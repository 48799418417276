@mixin align($horizontal, $vertical) {
    position: absolute;
    @if ($horizontal == center) {
        left: 50%;

        @if ($vertical == center) {
            transform: translate(-50%, -50%);
        }
    }

    @if ($vertical == center) {
        top: 50%;

        @if ($vertical != center) {
            transform: translateY(-50%);
        }
    }
}


@mixin align-contents($horizontal, $vertical) {
    display: flex;

    @if ($horizontal == center) {
        justify-content: center;
    }

    @if ($vertical == center) {
        align-items: center;
    }
}
