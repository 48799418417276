// TODO: Remove this; linear gradients are expensive - use image instead
@mixin radial-background {
    background: radial-gradient(ellipse at center, #{lighten($theme-primary-color, 7)}, $theme-primary-color);
}

@mixin gradient-horizontal-three-colors($start-color: #fff, $mid-color: #000, $color-stop: 50%, $end-color: #fff) {
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
}

@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}
