.lightset-wizard__result-wrapper {
    padding-top: $base-gutter;
    display: flex;
    justify-content: space-between;
}

.lightset-wizard__sidebar {

    &.lightset-wizard__sidebar--wide {
        width: 400px
    }

    & + label {
        margin-top: 20px;
    }

    h2 {
        font-size: $font-size-base;
    }
}

.lightset-wizard__change-color-fieldset {
    margin-top: 20px;
}

.lightset-wizard__background-options {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.lightset-wizard__select-background-section,
.lightset-wizard__project-info-section {
    margin-top: $base-gutter;
}

.lightset-wizard__background-option {
    text-align: center;
    color: $body-color;
	margin-right: 10px;
    font-size: 12px;
    font-family: $font-family-medium;
    line-height: 1em;
    width: 70px;

    &.active img, &.active:hover img {
        border-color: $light-blue;
        border-width: 2px;
    }

    &:hover, &:focus {
        text-decoration: none;

        img {
            border-color: darken($grey-dark, 20%);
        }
    }

    img {
        width: 70px;
        height: 70px;
        display: block;
        border: 1px solid $grey-dark;
        margin-bottom: 10px;
    }
}

.lightset-wizard__text-fieldset {

    label, input {
        display: inline-block;
    }

    label{
        width: 33%;
    }

    input {
        width: 66%;
        height: 32px;
        padding: 6px;
    }

    & + & {
        margin-top: 5px;
    }
}
