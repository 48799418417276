.popover {
    border-radius: 3px;
    width: 300px;
    background-color: white;
    padding: 20px;
    transform: translate(-50%, calc(-100% - 30px));
    border: 1px solid $grey-dark;
    position: absolute;
    z-index: 999;
    @extend %box-shadow-close;

    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
    }

    &:after {
        border-top-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }
    &:before {
        border-top-color: $grey-dark;
        border-width: 11px;
        margin-left: -11px;
    }

    &.popover--default {
        position: absolute;
        top: 40%;
        left: calc(50% - 150px);
        transform: translate(0, 0);

        &:after, &:before {
            display: none;
        }
    }

    &.popover--fill {
        top: 0;
        left: auto;
        width: 100%;
        height: 100%;
        transform: none;

        &:after, &:before {
            display: none;
        }
    }

    &.popover--lock {
        &:after {
            background: rgba($secondary-color, 0.7);
            display: block;
            content: '';
            border: 0;
            margin-left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;            
        }
    }

    &.popover--top-left {
        transform: translate(-41px, 25px);
    }

    &.popover--top-right {
        transform: translate(calc(-100% + 41px), 25px);
    }

    &.popover--bottom-left {
        transform: translate(-41px, calc(-100% - 30px));
    }

    &.popover--bottom-right {
        transform: translate(calc(-100% + 41px), calc(-100% - 30px));
    }

    &.popover--top-left,
    &.popover--top-right {
        &:after, &:before {
            border-top-color: transparent;
            top: auto;
            bottom: 100%;
        }

        &:after {
            border-bottom-color: #ffffff;
        }

        &:before {
            border-bottom-color: $grey-dark;
        }
    }

    &.popover--bottom-left,
    &.popover--top-left {
        &:after, &:before {
            left: 40px;
        }
    }

    &.popover--bottom-right,
    &.popover--top-right {
        &:after, &:before {
            left: calc(100% - 40px);
        }
    }
}

.popover__header {
    font-family: $font-family-bold;
    color: $primary-color;
}

.popover__title {
    font-family: $font-family-bold;
    color: $primary-color;
    margin-bottom: 10px;
}

.popover__list {
    padding-left: 1em;
    margin: 0;
}

body.popover--open {
    overflow: hidden;
}

