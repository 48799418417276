.form-horizontal {
    .form-group {
        white-space: nowrap;
    }

    .form-group--actions {
        padding-left: 25%;
    }

    label {
        width: 25%;
        display: inline-block;
    }

    .form-control {
        width: 75%;
        display: inline-block;
    }
}

.form-group:not(:last-child) {
    margin-bottom: 10px;
}

.form-group--error {
    color: $theme-warning;
}

.form-required {
    color: $light-blue;

    .form-group--error & {
        color: $theme-warning;
    }
}

.form-control {
    display: block;
    width: 100%;
    border: 1px solid $grey-dark;
    border-radius: 2px;
    padding: 6px 9px;

    &::placeholder {
        color: $grey-dark;
    }
}

.form-control--error {
    border-color: rgba($theme-warning, 0.6);
    color: $theme-warning;

    &::placeholder {
        color: $theme-warning;
    }
}
