$font-family-base: 'centrale_sans', Arial, Verdana, sans-serif;
$font-family-medium: 'centrale_sans_medium', Arial, Verdana, sans-serif;
$font-family-bold: 'centrale_sans_bold', Arial, Verdana, sans-serif;
$font-family-extra-bold: 'centrale_sans_xbold', Arial, Verdana, sans-serif;

$font-size-root:            14px;
$font-size-base:            1rem;
$font-size-lg:              1.25rem !default;
$font-size-sm:              .875rem !default;
$font-size-xs:              .75rem !default;

$font-size-h1:              38px;
$font-size-h2:              26px;
$font-size-h3:              25px;
$font-size-h4:              25px;
$font-size-h5:              25px;

$line-height:               1.5;

$line-height-h1:            round(($font-size-h1 * 1.10)); // ~110px
$line-height-h2:            round(($font-size-h2 * 1.25)); // ~50px
$line-height-h3:            round(($font-size-h3 * 1.42)); // ~34px
$line-height-h4:            round(($font-size-h4 * 1.50)); // ~30px
$line-height-h5:            round(($font-size-h5 * 1.83)); // ~22px
