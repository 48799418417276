@mixin button-style($background-color, $border-color, $text-color, $background-color-hover: $background-color, $background-color-active: $background-color-hover, $shadow-color: null, $shadow-opacity: 0.4) {
    background-color: $background-color;
    border-color: $border-color;
    color: $text-color;

    &[disabled] {
		color: $grey-dark;
        background-color: $grey-light !important;
		border-color: $grey-light !important;
    }

    &:hover {
        background-color: $background-color-hover;
    }

    &:active {
		@if $shadow-color {
    		box-shadow: inset 0 0 5px rgba($shadow-color, $shadow-opacity);
		}

		background-color: $background-color-active;
    }
}

.button {
    display: inline-block;
    padding: 6px 22px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    transition: all 0.2s;
    cursor: pointer;
    text-decoration: none;
    @include clear-outline;

    [class^="icon-"], [class*=" icon-"] {
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
}

a.button:hover,
a.button:focus {
    text-decoration: none;
}

.button--primary {
    @include button-style($primary-color, $primary-color, $white, $primary-color-hover, null, $very-dark-blue);
}

.button--primary-inverted {
    @include button-style($white, $white, $primary-color, rgba($white, .9), rgba($white, .7));
}

.button--transparent {
    @include button-style(transparent, $primary-color, $primary-color, rgba($very-light-aqua, .4), rgba($very-light-aqua, .7), $group-blue-1, 0.5);
}

.button--transparent-inverted {
    @include button-style(transparent, $white, $white, rgba($very-light-aqua, .1), rgba($very-light-aqua, .2));
}

.text-button {
    @include clear-button-style;
    @include clear-outline;
    padding: 0;

   [class^="icon-"], [class*=" icon-"] {
        position: relative;
        top: 1px;
        color: $primary-color;
        margin-left: 3px;
    }
}

.close {
    color: inherit;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: right;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    @include clear-outline;
}
