%blue-selection-overlay {
    content: "";
    background-color: rgba($light-blue, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.lightset-wizard__feature-carousel {
    height: 100%;
    width: calc(100% - 60px);
    margin: auto;
    position: relative;

    &.no-data {
        width: calc(100% - 400px);
        padding-top: 150px;
        text-align: center;
        font-size: 20px;
        font-family: $font-family-medium;
        color: $light-blue;
    }
}

.lightset-wizard__prev-slide,
.lightset-wizard__next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include clear-button-style;
    @include clear-outline;
    font-size: 40px;
    color: $very-light-blue;
    transition: color 0.15s;
    padding: 10px;
    border: 0;

    &[disabled] {
        display: none;
    }

    &:hover {
        color: $light-blue;
    }
}

.lightset-wizard__prev-slide {
    left: -40px;
}

.lightset-wizard__next-slide {
    right: -40px;
}

.lightset-wizard__slides {
    @include clear-list-style;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: 20px 0;

    .lightset-wizard__slides-wrapper--carousel-actived & {
        justify-content: flex-start;
    }
}

.lightset-wizard__slides-viewport {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.lightset-wizard__slides-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:not(.no-animate) {
        transition: transform 0.5s;
    }
}

.lightset-wizard__feature {
    text-align: center;
    width: calc(100%/6);
    padding: 20px;
    height: 100%;
    border: 2px solid transparent;
    cursor: pointer;
    flex: none;
    max-height: 100%;
    position: relative;

    &.lightset-wizard__feature--active {
        border-color: $light-blue;

        // Can't do background color because images arent transparent
        // so we do a pseudo element with a bg color over it
        &::after {
            @extend %blue-selection-overlay
        }

        .lightset-wizard__feature-title {
            font-weight: bold;
        }
    }

    .no-touchevents &:not(.lightset-wizard__feature--active):hover {
        &::after {
            @extend %blue-selection-overlay
        }
        & .lightset-wizard__feature-image {
            transform: scale(1);
        }
    }
}

.lightset-wizard__feature-contents {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}

.lightset-wizard__feature-image {
    object-fit: contain;
    max-height: 230px;
    max-width: 100%;
    display: block;
    margin: 0 auto 10px;
    transform: scale(0.9);
    transition: transform 0.1s;
    font-family: 'object-fit: contain;';

    .no-touchevents &:hover,
    .lightset-wizard__feature--active & {
        transform: scale(1);
    }
}

.lightset-wizard__features-view {
    height: 100%;
}

.lightset-wizard__feature-row {
    height: 50%;

    &.lightset-wizard__feature-row--heights .lightset-wizard__feature-image {
        max-width: 80px;
    }

    &:first-child {
        border-bottom: 1px solid $grey;
    }
}
