@font-face {
    font-family: 'icons';
    src:
        url('../fonts/icons.ttf?hyiphc') format('truetype'),
        url('../fonts/icons.woff?hyiphc') format('woff'),
        url('../fonts/icons.svg?hyiphc#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    @include icon;
}

.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-crosshairs {
    &:before {
        content: $icon-crosshairs;
    }
}
.icon-open-project {
    &:before {
        content: $icon-open-project;
    }
}
.icon-philips-shield {
    &:before {
        content: $icon-philips-shield;
    }
}
.icon-blur {
    &:before {
        content: $icon-blur;
    }
}
.icon-contrast {
    &:before {
        content: $icon-contrast;
    }
}
.icon-exposure {
    &:before {
        content: $icon-exposure;
    }
}
.icon-line {
    &:before {
        content: $icon-line;
    }
}
.icon-luminosity {
    &:before {
        content: $icon-luminosity;
    }
}
.icon-stamp {
    &:before {
        content: $icon-stamp;
    }
}
.icon-undo {
    &:before {
        content: $icon-undo;
    }
}
.icon-redo {
    &:before {
        content: $icon-redo;
    }
}
.icon-crop {
    &:before {
        content: $icon-crop;
    }
}
.icon-add {
    &:before {
        content: $icon-add;
    }
}
.icon-upload-image {
    &:before {
        content: $icon-upload-image;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-back-arrow {
    &:before {
        content: $icon-back-arrow;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-eraser {
    &:before {
        content: $icon-eraser;
    }
}
.icon-flip {
    &:before {
        content: $icon-flip;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-remove {
    &:before {
        content: $icon-remove;
    }
}
.icon-rotate-3d {
    &:before {
        content: $icon-rotate-3d;
    }
}
.icon-duplicate {
    &:before {
        content: $icon-duplicate;
    }
}
.icon-color {
    &:before {
        content: $icon-color;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-transform {
    &:before {
        content: $icon-transform;
    }
}
.icon-hide {
    &:before {
        content: $icon-hide;
    }
}
.icon-show {
    &:before {
        content: $icon-show;
    }
}
.icon-zoom {
    &:before {
        content: $icon-zoom;
    }
}
.icon-rotate {
    &:before {
        content: $icon-rotate;
    }
}
.icon-reset {
    &:before {
        content: $icon-reset;
    }
}
.icon-twitter-square {
    &:before {
        content: $icon-twitter-square;
    }
}
.icon-facebook-square {
    &:before {
        content: $icon-facebook-square;
    }
}
.icon-pinterest-square {
    &:before {
        content: $icon-pinterest-square;
    }
}
.icon-google-plus-square {
    &:before {
        content: $icon-google-plus-square;
    }
}
.icon-youtube-square {
    &:before {
        content: $icon-youtube-square;
    }
}
.icon-rotate-perspective {
    &:before {
        content: $icon-rotate-perspective;
    }
}


