$icon-image: "\f03e";
$icon-crosshairs: "\f05b";
$icon-open-project: "\f07c";
$icon-philips-shield: "\e900";
$icon-blur: "\e901";
$icon-contrast: "\e902";
$icon-exposure: "\e903";
$icon-line: "\e904";
$icon-luminosity: "\e905";
$icon-stamp: "\e906";
$icon-undo: "\e907";
$icon-redo: "\e920";
$icon-crop: "\e908";
$icon-add: "\e909";
$icon-upload-image: "\e90a";
$icon-check: "\e90b";
$icon-back-arrow: "\e90c";
$icon-arrow-right: "\e90d";
$icon-arrow-left: "\e90e";
$icon-arrow-up: "\e90f";
$icon-arrow-down: "\e910";
$icon-eraser: "\e911";
$icon-flip: "\e912";
$icon-info: "\e913";
$icon-remove: "\e914";
$icon-rotate-3d: "\e915";
$icon-duplicate: "\e916";
$icon-color: "\e917";
$icon-close: "\e918";
$icon-transform: "\e919";
$icon-hide: "\e91a";
$icon-show: "\e91b";
$icon-zoom: "\e91c";
$icon-rotate: "\e91d";
$icon-reset: "\e91e";
$icon-reset-flipped: "\e91f";
$icon-twitter-square: "\f081";
$icon-facebook-square: "\f082";
$icon-pinterest-square: "\f0d3";
$icon-google-plus-square: "\f0d4";
$icon-youtube-square: "\f167";
$icon-rotate-perspective: "\f166";

