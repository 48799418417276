.language {
    margin-bottom: 14px;

    &__label {
        margin-right: 10px;
        position: relative;
        top: 0;
    }

    &__select {
        display: inline-block;
        min-width: 200px;
        width: auto;
    }
}
