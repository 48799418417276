$notification-well-min-height: 100px;
$well-transition-speed: .2s;

@mixin well-style($text-color, $background-color) {
    color: $text-color;
    background-color: $background-color;
}

.well {
    .well__wrapper {
        padding: 19px 80px 18px 60px;
    }

    p {
        margin-bottom: 0;
    }

    .well__toggle {
        @include linear-gradient(rgba($theme-info, .8), $theme-info);
        transform: translateY(-100%);
        position: absolute;
        top: 0;
        right: 10px;
        background-color: $theme-info;
        border: 0;
        padding: 8px;
        color: $white;
        width: 140px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        
        &:focus {
            outline: 0;
        }

        i {
            margin-right: 5px;
            font-weight: bold;
            color: $white;
        }
    }
}

.well-info {
    @include well-style($white, $theme-info);
}

.well--warning {
    @include well-style($white, $theme-warning);
}

.well-info {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: $notification-well-min-height;
    transition: height $well-transition-speed ease-in-out;

    .well__label--hide {
        display: inline-block;
    }

    .well__label--show {
        display: none;
    }

    &.well--hide {
        height: 0;
        min-height: 0;
        padding: 0;

        .well__label--show {
            display: inline-block;
        }

        .well__label--hide {
            display: none;
        }

        .well__wrapper {
            padding: 0;
        }

        .well__contents {
            display: none;
        }
    }
}
