.progress {
    width: 100%;
    height: 5px;
    position: relative;
    background-color: $grey;
    
    &.progress--large {
        height: 10px;
    }

    &.progress--inverted {
        background-color: $white;

        .progress__fill {
            background-color: $light-blue;
        }
    }
}

.progress__fill {
    background-color: $primary-color;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.2s linear;
}

.progress__text {
    position: absolute;
    right: 0;
    text-align: center;
    color: $white;
    top: 20px;
    transform: translateX(50%);
}
