.spinner {
    display: inline-block;
    background: none;
    position: relative;
    width: 100px;
    height: 100px;
    transform: scale(0.7);

    &.spinner--inverted::after {
        box-shadow: 0 3px 0 0 $primary-color;
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 80px;
        height: 80px;
        top: 10px;
        left: 10px;
        border-radius: 40px;
        box-shadow: 0 3px 0 0 $white;
        animation: rotate 1s linear infinite;
    }

    &.spinner--centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.spinner--on-top {
        z-index: 10;
    }
}
