.slider--container {
    display: inline-block;
}

.slider--function-button-container {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    margin-bottom: -10px;
}

.slider {
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    
    &[data-orientation="vertical"] {
        flex-direction: column;
        padding: 0;

        .slider__slider {
            min-height: 200px;
            width: 1px;
            min-width: 0;
            margin: 15px 0;
        }

        .slider__max-value {
            order: 2;
        }

        .slider__slider {
            order: 3;
        }

        .slider__min-value {
            order: 4;
        }

        .slider__handle {
            left: 50%;
        }

        .slider__label {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &[data-orientation="horizontal"] {

		padding: 0 20px !important;

        .slider__rotate {
			padding: 20px 0;
            width: 70px;
            height: 100%;
            display: block;
            background-color: $white;
            border: 0;
            position: relative;
            user-select: none;
            outline: 0;

            &.slider__rotate--left {
                margin-right: 10px;
				padding-right: 20px;
				border-right: 1px solid #eee;
            }

            &.slider__rotate--right {
				margin-left: 10px;
				padding-left: 20px;
				border-left: 1px solid #eee;
            }
        }

    }
}

.slider:not([data-orientation="vertical"]) .slider__min-value {
    margin-right: 5px;
}

.slider__label {
    color: $light-blue;
    font-family: $font-family-bold;
    margin-bottom: 0;
    margin-right: 20px;
}

.slider__slider {
    height: 1px;
    min-width: 200px;
    flex: 999;
    margin: 0 15px;
    position: relative;
    background-color: $light-blue;
}

.slider__fill {
    position: absolute;
	background-color: $blue;
	
	&.vertical {
		bottom: 0;
	    left: -1px;
	    width: 3px;
	    height: 50%;
	}

	&.horizontal {
		top: -1px;
		left: 0px;
		height: 3px;
		width: 50%;
	}
}

.slider__zero {
    position: absolute;
    bottom: 25%;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    transform: translate(-50%, 50%);
    border: 1px solid $light-blue;
    cursor: pointer;
}

.slider__handle {
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    transform: translate(-50%, 50%);
    border: 1px solid $light-blue;
    cursor: pointer;

    &.slider__handle--with-icon {
        width: 29px;
        height: 29px;
    }
}

.slider__handle-icon {
    font-size: 16px;
    color: $light-blue;
    @include align(center, center);
}