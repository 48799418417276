.intro {
    padding: 50px 100px;
}

.intro__wrapper {
    display: flex;
}

.intro__wrapper img {
	width: 184px;
    height: 283px;
    display: block;
}

.intro__text {
    margin-left: 60px;
}

.intro__title {
    margin-top: 10px;
}
