.lightset-preview__preview-container {
    @extend %box-shadow-close;
    padding: 40px;
    position: relative;

    & #lightset-preview {
        border-bottom: 2px solid #555;
    }

    .touchevents & {
        transform: scale(0.95);
        transform-origin: top left;
    }
}

.lightset-preview__preview-project-name,
.lightset-preview__preview-title {
    height: 1.2em;
    font-weight: normal;
    line-height: 1.2em;
}

.lightset-preview__preview-title {
    color: $light-blue;
}

.lightset-preview__preview-title {
    font-size: 16px;
    margin-bottom: 0;
}

.lightset-preview__preview-footer {
    margin-top: 15px;
    display: flex;
}

.lightset-preview__preview-disclaimer {
    width: 380px;
}

.lightset-preview__preview-disclaimer-title {
    font-weight: normal;
    font-size: 9px;
    margin: 0 0 .3em;
}

.lightset-preview__preview-disclaimer-text {
    margin: 0;
    font-size: 7.5px;
    line-height: 1em;
}

.lightset-preview__preview-logo {
    width: 80px;
    margin-left: 20px;

    img {
        max-width: 100%;
        height: auto;
    }
}

.lightset-preview__preview-product-data {
    position: absolute;
    width: 300px;
    left: 53%;
    top: 45%;
    font-size: 10px;

    p {
        margin: 0;
        line-height: 1.3;
    }

    p + p {
        margin-top: 8px;
    }

    .lightset-preview__custom-product-text-box {
        background-color: #ededed;
        display: none;

        #lightset-preview__custom-product-text-form {
            width: 260px;
            margin: auto;
            padding: 18px 0;

            button {
                font-size: 14px;
                margin-right: 12px;
            }

            .lightset-wizard__cancel-custom-text{
                font-size: 14px;
                color: #009acd;
                font-weight: bold;
                cursor: pointer;
            }

            textarea{
                width: 100%;
                height: 220px;
                text-align: left;
                padding: 8px;
                max-height: 220px;
                border: 0;
                border-radius: 10px;
                margin-bottom: 17px;
                display: block;
            }

        }

    }

    .lightset-preview__product-text {
        min-height: 98px;
        max-height: 285px;
        padding-left: 35px;
        width: 275px;
        overflow: hidden;
    }

    .lightset-preview__custom-product-text-change{

        color: #009acd;
        display: block;
        background-image: url(/creator/img/lightset-wizard/edit-custom-product-text.png);
        background-repeat: no-repeat;
        background-position-y: 2px;
        padding-left: 12px;
        cursor: pointer;
        margin-top: 8px;
        margin-left: 35px;

    }
}



.lightset-preview__color-label {
    display: block;
    height: 1em;
}
