.select {
    width: 100%;
    position: relative;
}

.select__toggle {
    cursor: pointer;
    border: 1px solid $light-blue;
    border-radius: 2px;
}

.select__option {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.select__color-preview {
    margin: 5px;
    height: 24px;
    width: 24px;

    & + .select__option {
        padding-left: 0;
    }
}

.select__arrow {
    @include icon;
    position: absolute;
    right: 10px;
    top: -3px;
    bottom: 0;
    line-height: 1.3;
    font-size: 32px;
    color: $primary-color;
    pointer-events: none;

    &::before,
    .select--expand-up.select--expanded &:before {
        content: $icon-arrow-down;
    }

    .select--expanded &:before,
    .select--expand-up &:before {
        content: $icon-arrow-up; 
    }
}

.select--expanded .select__options {
    display: block;
}

.select__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: lighten($light-blue, 20%);
    background-color: $white;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 2px;
    display: none;
    z-index: 1;

    .select--expand-up & {
        top: auto;
        bottom: 100%;
    }
    
    .select__option {
        cursor: pointer;

        &.select__option--selected {
            font-weight: bold;
        }

        &:hover {
            background-color: lighten($light-blue, 40%);
        }
    }
}

.select__options--scroll {
    &::-webkit-scrollbar {
        width: 24px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:vertical {
        background-color: rgba(0,0,0,.2);
        -webkit-border-radius: 12px;
        height: 80px;
        box-shadow: inset 0 0 0 9px white;
    }
}
