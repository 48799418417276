// COLOR DEFINITIONS

// Primary colors

$very-light-blue: #b3c8e6;
$very-light-aqua: #cae3e9;
$light-blue: #0089c4;
$blue: #0066a1;
$dark-blue: #003478;
$very-dark-blue: #0f204b;
$group-blue-1: #72b5cc;

$grey: #e0e0e0;
$grey-light: #efefef;
$grey-dark: #babbc6;

// Blacks
$black: #000;
$black-transparent-6: rgba($black, 0.6);
$black-transparent-5: rgba($black, 0.5);
$black-transparent-4: rgba($black, 0.4);
$black-transparent-3: rgba($black, 0.3);
$black-transparent-2: rgba($black, 0.2);

// Whites
$white: #fff;
$white-transparent-3: rgba($white, 0.3);

// Themes
$theme-success: #b8be29;
$theme-info: #e78222;
$theme-warning: #B9211E;

 // COLOR APPLICATIONS
$primary-color: $light-blue;
$primary-color-hover: #0073b4;
$primary-inverted-text-color: $white;
$secondary-inverted-text-color: $very-light-blue;

$secondary-color: #ffffff;
$secondary-border-color: #7fc4e1;
$secondary-text-color: $light-blue;
$secondary-color-hover: #f2f9fc;

$push-shadow-color: #0b2265;

// Body
$body-color: #3c3c3c;
$body-bg: $white;
