.toggle {
    display: flex;
    user-select: none;
    align-items: center;
    flex-direction: column;
    margin: 10px;
}

.toggle__switch {
    height: 56px;
    width: 26px;
    margin: 10px 0;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: mix($light-blue, $white, 50%);

    .toggle--on & {
        background-color: $light-blue;        
    }
}

.toggle__icon-show,
.toggle__icon-hide {
    color: $light-blue;
    transition: opacity 0.15s;
}



.toggle__icon-show {
    opacity: 0.6;
}

.toggle__icon-hide {
    opacity: 1;
}
.toggle--on  {

    .toggle__switch-handle {
        top: calc(100% - 25px);
    }

    .toggle__icon-show {
        opacity: 1;
    }

    .toggle__icon-hide {
        opacity: 0.6;
    }
}

.toggle__switch-handle {
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;            
    bottom: 3px;
    height: 22px;
    border-radius: 3px;
    background-color: white;
    transition: top 0.1s;


    &::before, &::after {
        content: "";
        position: absolute;
        left: 4px;
        right: 4px;
        height: 2px;
        border-radius: 1px;
        background-color: $light-blue;
    }

    &::before {
        top: 7px;
    }

    &::after {
        bottom: 7px;
    }
}
