@font-face{font-family:'centrale_sans';src:url('../fonts/CentraleSans-Book.eot');src:url('../fonts/CentraleSans-Book.eot?#iefix') format('embedded-opentype'),url('../fonts/CentraleSans-Book.woff') format('woff'),url('../fonts/CentraleSans-Book.ttf') format('truetype'),url('../fonts/CentraleSans-Book.svg#CentraleSans-Book') format('svg');font-weight:normal;font-style:normal}
@font-face{font-family:'centrale_sans_medium';src:url('../fonts/CentraleSans-Medium.eot');src:url('../fonts/CentraleSans-Medium.eot?#iefix') format('embedded-opentype'),url('../fonts/CentraleSans-Medium.woff') format('woff'),url('../fonts/CentraleSans-Medium.ttf') format('truetype'),url('../fonts/CentraleSans-Medium.svg#CentraleSans-Medium') format('svg');font-weight:normal;font-style:normal}
@font-face{font-family:'centrale_sans_bold';src:url('../fonts/CentraleSans-Bold.eot');src:url('../fonts/CentraleSans-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/CentraleSans-Bold.woff') format('woff'),url('../fonts/CentraleSans-Bold.ttf') format('truetype'),url('../fonts/CentraleSans-Bold.svg#centrale_sans_bold') format('svg');font-weight:normal;font-style:normal}
@font-face{font-family:'centrale_sans_xbold';src:url('../fonts/CentraleSans-Xbold.eot');src:url('../fonts/CentraleSans-Xbold.eot?#iefix') format('embedded-opentype'),url('../fonts/CentraleSans-Xbold.woff') format('woff'),url('../fonts/CentraleSans-Xbold.ttf') format('truetype'),url('../fonts/CentraleSans-Xbold.svg#centrale_sans_xbold') format('svg');font-weight:normal;font-style:normal}

* {
    -webkit-font-smoothing: antialiased;
}

h1, .h1 {
    font-size: $font-size-h1;
    line-height: $line-height-h1;
    font-family: $font-family-extra-bold;
    color: $primary-color;
}

h2, .h2 {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    font-family: $font-family-bold;
}
