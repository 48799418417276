@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
