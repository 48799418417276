@mixin clear-list-style {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

@mixin clear-button-style {
    border-color: transparent;
    background-color: transparent;
    outline: 0;
}

@mixin clear-outline {
    &:focus {
        outline:0;
    }
}

@mixin clear-text-decoration {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
