$lightset-wizard-header-height: 80px;
$lightset-wizard-footer-height: 80px;

.lightset-wizard,
.lightset-wizard__main-region {
    height: 100%;
}

.lightset-wizard__main-region {
    padding-top: 10px;
}

.lightset-wizard__header {
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

.lightset-wizard__footer {
    @include clearfix;
    padding-top: 20px;

    .lightset-wizard__back {
        float: left;
    }

    .lightset-wizard__skip-to-poles,
    .lightset-wizard__next {
        margin-left: 10px;
        float: right;
    }
}

.lightset-wizard__header{
    height: $lightset-wizard-header-height;
}

.lightset-wizard__title {
    font-family: $font-family-base;
    margin: 0;
    flex: 99;
}

.lightset-wizard__close.close {
    margin-left: 20px;
    margin-top: 1px;
    color: $primary-color;
}

.lightset-wizard__main {
    height: calc(100% - #{$lightset-wizard-header-height + $lightset-wizard-footer-height});
    position: relative;
}

.lightset-wizard__progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.lightset-wizard__footer {
    border-top: 1px solid $grey;
    height: $lightset-wizard-footer-height;
}

.lightset-wizard__back {
    @include clear-button-style;

    .icon-back-arrow {
        position: relative;
        top: 1px;
        color: $light-blue;
        margin-right: 4px;
    }
}
