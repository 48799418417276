.render {
    @include align-contents(center, center);
    height: 100%;
    background: linear-gradient(-60deg, $blue, $blue 30%, $dark-blue 70%, $dark-blue);

    .render__progress {
        width: 400px;
        margin: 30px auto;
        height: 100px;
        position: relative;
    }

    > div {
        position: relative;
    }
}

.render__contents {
    min-width: 552px;
    text-align: center;
    color: $white;
}

.render__intro-text {
    max-width: 500px;
    margin: 0 auto 20px;
}

.render__actions {
    margin: 40px 0;

    .button + .button {
        margin-left: 10px;
    }
}


.render__icon {
    display: block;
    margin-bottom: 40px
}

.render__preview {
    position: relative;
}

.render__check-svg {
  fill: none;
  stroke: $white;
  stroke-width: 20;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: draw 1s ease forwards;
}

.render__title-input {
    font-size: 36px;
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    padding: 10px 15px 5px;
    background-color: rgba($blue, 0.3);
    font-family: $font-family-bold;
    color: $white;
    outline: none;
    border: none; 

    &:focus {
        border-color: $white;        
    }

    &::placeholder {
        color: rgba($white, 0.6);
    }
}
